<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isSmall?: boolean
    isWhiteSpacePreLine?: boolean
    status: 'error' | 'help' | 'info' | 'success' | 'warning'
  }>(),
  { isSmall: false, isWhiteSpacePreLine: false }
)

const className = computed(() => {
  const { status } = props

  if (['error', 'info', 'success'].includes(status)) {
    return status
  }

  return status
})
const svgIconName = computed(() => {
  switch (props.status) {
    case 'error':
      return 'outlined/error-outline'
    case 'help':
      return 'outlined/help-outline'
    case 'info':
      return 'outlined/info'
    case 'success':
      return 'outlined/check-circle'
    case 'warning':
      return 'outlined/warning-amber'
    default:
      return null
  }
})
</script>

<template>
  <div class="notification" :class="[className, { small: isSmall }]">
    <SvgIcon v-if="svgIconName" :name="svgIconName" />

    <div :class="{ 'white-space-pre-line': isWhiteSpacePreLine }">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.notification {
  align-items: center;
  border-radius: 12px;
  display: flex;
  padding: 16px;

  @each $status, $color in (
    'error': $main-red-color,
    'help': $main-blue-color,
    'info': $main-blue-color,
    'success': $main-green-color,
    'warning': $main-orange-color
  ) {
    &.#{$status} {
      background: mix($color, white, 10%);

      > svg {
        color: $color;
      }
    }
  }

  &.small {
    border-radius: 4px;
    padding: 4px 8px;

    > svg {
      font-size: 16px;
    }
  }

  > svg {
    font-size: 32px;
    margin-right: 8px;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}
</style>
