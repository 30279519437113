import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Orders extends HttpFactory {
  private resource = 'v1/orders'

  public createOrder (data: object) {
    const { guestId, token } = this.authStore

    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        params: { ...(!token && guestId ? { guestId } : {}) },
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(token) }
      }
    )
  }

  public getOrder (orderId: string, params: object = {}) {
    return this.$fetch(
      `${this.resource}/${orderId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getOrders (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateOrder (orderId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${orderId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteOrder (orderId: string) {
    return this.$fetch(
      `${this.resource}/${orderId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
