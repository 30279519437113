import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Export extends HttpFactory {
  private resource = 'export'

  public export (dataForExport: string, format: string, params: object) {
    return this.$fetch(
      `${this.resource}/${dataForExport}.${format}`,
      { headers: getAuthHeaders(this.authStore.token), params, responseType: 'arrayBuffer' }
    )
  }
}
