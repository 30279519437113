import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import AuthScope from '~/enums/auth-scope'

export default class Brands extends HttpFactory {
  private resource = 'v1/brands'

  public createBrand (formData: FormData, authScope: AuthScope) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getBrand (brandId: string) {
    return this.$fetch(`${this.resource}/${brandId}`)
  }

  public getBrands (params: object, authScope?: AuthScope|null) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateBrand (brandId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${brandId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, AuthScope.Admin) }
    )
  }

  public deleteBrand (brandId: string) {
    return this.$fetch(
      `${this.resource}/${brandId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token, AuthScope.Admin) }
    )
  }
}
