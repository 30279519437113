import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class QualityCertificates extends HttpFactory {
  private resource = 'v1/qualityCertificates'

  public createQualityCertificate (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getQualityCertificate (qualityCertificateId: string, params: object) {
    return this.$fetch(
      `${this.resource}/${qualityCertificateId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getQualityCertificates (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateQualityCertificate (qualityCertificateId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${qualityCertificateId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
