import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Users extends HttpFactory {
  private resource = 'v1/users'

  public getUser (userId: string, params?: object) {
    return this.$fetch(
      `${this.resource}/${userId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getUsers (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateUser (userId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteUser (userId: string) {
    return this.$fetch(
      `${this.resource}/${userId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public createUserAddress (userId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/addresses`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getUserAddress (userId: string, addressId: string) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/addresses/${addressId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getUserAddresses (userId: string, params: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/addresses`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public updateUserAddress (userId: string, addressId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/addresses/${addressId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteUserAddress (userId: string, addressId: string) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/addresses/${addressId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public createUserBillingAccount (userId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/billingAccount`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getUserBillingAccount (userId: string) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/billingAccount`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public updateUserBillingAccount (userId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/billingAccount`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getUserOrder (userId: string, orderId: string, params: object = {}) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/orders/${orderId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getUserOrders (userId: string, params: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/orders`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public createUserPoint (userId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/points`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getUserPoints (userId: string, params: object) {
    return this.$fetch(
      `${this.resource}/${userId}/relationships/points`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }
}
