import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Tests extends HttpFactory {
  private resource = 'v1/tests'

  public createTest (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getTest (testId: string, params?: object) {
    return this.$fetch(`${this.resource}/${testId}`, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public getTests (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateTest (testId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${testId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteTest (testId: string) {
    return this.$fetch(
      `${this.resource}/${testId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
