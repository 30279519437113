import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'

export default class SupplierFaqs extends HttpFactory {
  private resource = 'v1/supplierFaqs'

  public createSupplierFaq (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getSupplierFaq (supplierFaqId: string) {
    return this.$fetch(
      `${this.resource}/${supplierFaqId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSupplierFaqs (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateSupplierFaq (supplierFaqId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${supplierFaqId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteSupplierFaq (supplierFaqId: string) {
    return this.$fetch(
      `${this.resource}/${supplierFaqId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
