import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type SubscriptionForm from '~/enums/subscription-form'

export default class Subscribers extends HttpFactory {
  private resource = 'v1/subscribers'

  public createSubscriber (email: string, subscriptionForm: SubscriptionForm) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify({ email, subscriptionFormId: subscriptionForm }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}
