import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class PromoCodeApplications extends HttpFactory {
  private resource = 'v1/promoCodeApplications'

  public createPromoCodeApplication (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getPromoCodeApplication (promoCodeApplicationId: string) {
    return this.$fetch(
      `${this.resource}/${promoCodeApplicationId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getPromoCodeApplications (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updatePromoCodeApplication (promoCodeApplicationId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${promoCodeApplicationId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}
