enum UserRole {
  Admin = '1',
  Client = '2',
  Supplier = '3',
  Marketer = '4',
  ClientManager = '5',
  SupplierManager = '6',
  Storekeeper = '7',
  Accountant = '8',
  SeoSpecialist = '9',
  CallCenterOperator = '10',
  ContentManager = '11',
  SalesManager = '12',
  HeadOfOffline = '13',
  Specialist = '14',
  MarketplaceManager = '15',
  MarketingAssistant = '16',
  SpecialistManager = '17'
}

const {
  Accountant,
  Admin,
  CallCenterOperator,
  Client,
  ClientManager,
  ContentManager,
  HeadOfOffline,
  Marketer,
  MarketingAssistant,
  MarketplaceManager,
  SalesManager,
  SeoSpecialist,
  Specialist,
  Storekeeper,
  Supplier,
  SupplierManager,
  SpecialistManager
} = UserRole

const nameMapping = new Map([
  [Accountant, 'Бухгалтер'],
  [Admin, 'Администратор'],
  [CallCenterOperator, 'Оператор call-центра'],
  [Client, 'Клиент'],
  [ClientManager, 'Менеджер по работе с клиентами'],
  [ContentManager, 'Контент-менеджер'],
  [HeadOfOffline, 'Руководитель офлайн-направления'],
  [Marketer, 'Маркетолог'],
  [MarketingAssistant, 'Ассистент по маркетингу'],
  [MarketplaceManager, 'Маркетплейс-менеджер'],
  [SalesManager, 'Менеджер по продажам'],
  [SeoSpecialist, 'SEO-специалист'],
  [Specialist, 'Специалист'],
  [Storekeeper, 'Кладовщик'],
  [Supplier, 'Поставщик'],
  [SupplierManager, 'Менеджер по работе с поставщиками'],
  [SpecialistManager, 'Менеджер по работе с специалистами']
])

namespace UserRole {
  export function getName (userRole: UserRole): string {
    return nameMapping.get(userRole)!
  }

  export function hasAccessToAdminPanel (userRole: UserRole): boolean {
    return [
      Accountant,
      Admin,
      CallCenterOperator,
      ClientManager,
      ContentManager,
      HeadOfOffline,
      Marketer,
      MarketingAssistant,
      MarketplaceManager,
      SalesManager,
      SeoSpecialist,
      Storekeeper,
      SupplierManager,
      SpecialistManager
    ].includes(userRole)
  }
}

export default UserRole
