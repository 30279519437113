import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import api_helper_FydsJvQ4dE from "/usr/src/app/plugins/api-helper.ts";
import maska_UHaKf2z1iQ from "/usr/src/app/plugins/maska.ts";
import pwa_update_client_etP3CbLkyW from "/usr/src/app/plugins/pwa-update.client.ts";
import vue_final_modal_pML93k5qcp from "/usr/src/app/plugins/vue-final-modal.ts";
import vue_lazyload_client_Zl9YF34NXo from "/usr/src/app/plugins/vue-lazyload.client.ts";
import vue3_toastify_client_GgudbfYtjc from "/usr/src/app/plugins/vue3-toastify.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  api_helper_FydsJvQ4dE,
  maska_UHaKf2z1iQ,
  pwa_update_client_etP3CbLkyW,
  vue_final_modal_pML93k5qcp,
  vue_lazyload_client_Zl9YF34NXo,
  vue3_toastify_client_GgudbfYtjc
]