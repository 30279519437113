import type { $Fetch } from 'nitropack'

import { useAuthStore } from '~/store/auth'

export default class HttpFactory {
  protected $fetch: $Fetch
  protected authStore: ReturnType<typeof useAuthStore>

  /**
   * HttpFactory constructor.
   *
   * @param {$Fetch}                          fetcher   Fetcher
   * @param {ReturnType<typeof useAuthStore>} authStore Auth store
   */
  constructor (fetcher: $Fetch, authStore: ReturnType<typeof useAuthStore>) {
    this.$fetch = fetcher
    this.authStore = authStore
  }
}
