import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Recommendations extends HttpFactory {
  private resource = 'v1/recommendations'

  public createRecommendation (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getRecommendation (recommendationId: string) {
    return this.$fetch(`${this.resource}/${recommendationId}`)
  }

  public getRecommendations (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateRecommendation (recommendationId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${recommendationId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteRecommendation (recommendationId: string) {
    return this.$fetch(
      `${this.resource}/${recommendationId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
