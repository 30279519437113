import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'
import OrderShippingType from '~/enums/order-shipping-type'

export default class Actions extends HttpFactory {
  private resource = 'v1/actions'

  public cancelPayment (orderId: string) {
    return this.$fetch(
      `${this.resource}/cancelPayment`,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public capturePayment (data: object) {
    return this.$fetch(
      `${this.resource}/capturePayment`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public createParcel (orderId: string) {
    return this.$fetch(
      `${this.resource}/createParcel`,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public createRefund (data: object) {
    return this.$fetch(
      `${this.resource}/createRefund`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getMonthlyBonus () {
    return this.$fetch(
      `${this.resource}/getMonthlyBonus`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getShippingCost (shippingType: OrderShippingType, weightInGrams: number, extraData: object) {
    return this.$fetch(
      `${this.resource}/getShippingCost`,
      { params: { shippingTypeId: shippingType, weightInGrams, ...extraData } }
    )
  }

  public exportBarcode (orderId: string) {
    return this.$fetch(
      `${this.resource}/exportBarcode`,
      {
        headers: getAuthHeaders(this.authStore.token),
        params: { orderId },
        responseType: 'arrayBuffer'
      }
    )
  }

  public exportData (exportName: string, authScope: AuthScope, data: object) {
    return this.$fetch(
      `${this.resource}/exportData`,
      {
        method: 'POST',
        body: JSON.stringify({ exportName, ...data }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) },
        responseType: 'arrayBuffer'
      }
    )
  }

  public getPaymentUrl (orderId: string) {
    return this.$fetch(
      `${this.resource}/getPaymentUrl`,
      { headers: getAuthHeaders(this.authStore.token), params: { orderId } }
    )
  }

  public giveFullRefund (orderId: string) {
    return this.$fetch(
      `${this.resource}/giveFullRefund`,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public updateProductStock (productId: string, deltaStock: number) {
    return this.$fetch(
      `${this.resource}/updateProductStock`,
      {
        method: 'POST',
        body: JSON.stringify({ deltaStock, productId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}
