<script lang="ts" setup>
import type { NuxtError } from '#app'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import RetailRocketApiService from '~/services/retail-rocket-api-service'

const props = defineProps<{
  error: NuxtError
}>()

const router = useRouter()

onMounted((): void => {
  setTimeout(RetailRocketApiService.render, 0)
})

useHead({
  title: props.error.statusCode === 404 ? 'Страница не найдена — Ошибка 404' : `Ошибка ${props.error.statusCode}`
})
</script>

<template>
  <NuxtLayout>
    <section>
      <div class="container">
        <div class="content-wrapper">
          <h1>Страница {{ error.statusCode }}</h1>

          <Notification v-if="error.statusCode === 404" class="mb24" status="error">
            К&nbsp;сожалению, запрашиваемая вами страница не&nbsp;найдена. Возможно, она была удалена, перемещена, или вы ввели неправильный адрес. Начните с&nbsp;главной страницы или воспользуйтесь поиском.
          </Notification>
          <template v-else>
            <Notification v-if="error.message" class="mb24" status="error">
              {{ error.message }}
            </Notification>

            <Notification class="mb24" is-small status="info">
              Если ошибка сохраняется продолжительное время, напишите, пожалуйста, письмо с&nbsp;описанием проблемы на&nbsp;<a :href="`mailto:${constants.supportEmail}`">{{ constants.supportEmail }}</a>. Укажите сообщение об&nbsp;ошибке и&nbsp;адрес страницы.
            </Notification>
          </template>

          <div class="buttons">
            <BasicButton color="green" is-small :title="'На\xA0главную'" @click="clearError({ redirect: Page.Main })" />
            <BasicButton color="body-background" is-small title="Назад" @click="router.back" />
          </div>
        </div>
      </div>
    </section>

    <!-- Специально для вас -->
    <RecommendationBlock :ids="['62de6fc9a46052a3d5db62a1', '6352860da27490b8467d83af']" />

    <!-- Хиты -->
    <RecommendationBlock :ids="['62de6fd49b6a8f8e07f08e3a', '6352861bbb745c063b0b5808']" />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.buttons {
  display: flex;

  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
