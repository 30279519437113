import type AuthScope from '~/enums/auth-scope'

type AuthHeaders = { Authorization?: string, 'X-Auth-Scope'?: string }

export const getAuthHeaders = (token: string | null, authScope: AuthScope | null = null): any => {
  const headers: AuthHeaders = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`

    if (authScope) {
      headers['X-Auth-Scope'] = authScope
    }
  }

  return headers
}
