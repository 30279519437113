import { defineStore } from 'pinia'

import type { NuxtError } from '#app'
import PageEnum from '~/enums/page'
import SpecialistApplicationStatus from '~/enums/specialist-application-status'
import SpecialistDocumentTypeEnum from '~/enums/specialist-document-type'
import helpers from '~/helpers'
import ApiErrorHandler from '~/helpers/api-error-handler'
import JsonApiHelper from '~/helpers/json-api-helper'
import { useUserStore } from '~/store/user'
import type { SpecialistData } from '~/types/specialists'

export const messages = {
  cannotCreateSpecialist: 'Не удалось создать специалиста.',
  cannotDeleteSpecialist: 'Не удалось удалить специалиста.',
  cannotGetSpecialist: 'Не удалось получить специалиста.',
  cannotGetSpecialistApplications: 'Не удалось получить заявки специалиста.',
  cannotGetSpecialistDocumentGroups: 'Не удалось получить документы специалиста.',
  cannotGetSpecialistImages: 'Не удалось получить аватарку.',
  cannotGetSpecialists: 'Не удалось получить специалистов.',
  cannotUpdateSpecialist: 'Не удалось сохранить профиль специалиста.',
  cannotUpdateSpecialistApplication: 'Не удалось обработать заявку специалиста.',
  emailFieldCannotBeEmpty: 'Поле «Email» не может быть пустым.',
  emptySpecialistApplications: 'Нет заявок на модерацию',
  emptySpecialists: 'Нет специалистов.',
  experienceFieldCannotBeEmpty: 'Поле «Стаж» не может быть пустым.',
  firstNameFieldCannotBeEmpty: 'Поле «Имя» не может быть пустым.',
  lastNameFieldCannotBeEmpty: 'Поле «Фамилия» не может быть пустым.',
  phoneNumberFieldCannotBeEmpty: 'Поле «Номер телефона» не может быть пустым.',
  specialistApplicationIsSaved: 'Заявка специалиста обработана',
  specialistIsCreated: 'Специалист создан.',
  specialistIsDeleted: 'Специалист удален.',
  specialistIsSaved: 'Профиль специалиста сохранен.'
}

type State = {
  authSpecialistId: string|null
  filter: {
    isForAdmin: string
    // helpingAdults: boolean
    // helpingChildren: boolean
    priceTo: number|null
    query: string
    serviceId: string|null
    specialityId: string|null
    // specializationId: string|null
  }
  form: any
  formApplication: any
  hasUnsavedChanges: boolean
  isApplicationDataLoading: boolean
  isDataLoading: boolean
  isSaving: boolean
  page: {
    number: number
    size: number
  }
  specialistsData: SpecialistData[]
  specialistData: SpecialistData|null
  totalPages: number|null
  totalSpecialists: number|null
}

const initialState = (): State => ({
  authSpecialistId: null,
  filter: {
    isForAdmin: 'no',
    // helpingAdults: false,
    // helpingChildren: false,
    priceTo: null,
    query: '',
    serviceId: null,
    specialityId: null
    // specializationId: null
  },
  form: {
    associations: [],
    avatar: null,
    avatarUrl: null,
    courses: [],
    deleteAssociationIds: [],
    deleteCourseIds: [],
    deleteDocumentGroupIds: [],
    deleteEducationIds: [],
    deleteAvatar: false,
    deleteProfessionalSkillIds: [],
    documents: [],
    educations: [],
    email: null,
    experience: null,
    firstName: null,
    helpingAdults: true,
    helpingChildren: true,
    lastName: null,
    link: null,
    patronymic: null,
    phoneNumber: null,
    priceFtfConsultation: null,
    priceRemoteConsultation: null,
    professionalSkills: [],
    services: [],
    socialNetworks: [],
    specialities: [],
    updateAssociations: [],
    updateCourses: [],
    updateEducations: [],
    updateProfessionalSkills: [],
    user: null
    // specializations: [],
  },
  formApplication: {
    adminComment: null,
    status: SpecialistApplicationStatus.InProcess
  },
  hasUnsavedChanges: false,
  isApplicationDataLoading: false,
  isDataLoading: false,
  isSaving: false,
  page: {
    number: 1,
    size: 20
  },
  specialistsData: [],
  specialistData: null,
  totalPages: null,
  totalSpecialists: null
})

export const useSpecialistsStore = defineStore('specialists', {
  state: () => initialState(),
  getters: {
    getDocuments: (state: State) => state.form.documents,
    getSpecialist: (state: State) => state.specialistData,
    getSpecialists: (state: State) => state.specialistsData,
    getSpecialistAssociations: (state: State) => state.specialistData?.relationships.associations.data || [],
    getSpecialistCourses: (state: State) => state.specialistData?.relationships.courses.data || [],
    getSpecialistDocumentGroups: (state: State) => state.specialistData?.relationships.documentGroups?.data || [],
    getSpecialistEducations: (state: State) => state.specialistData?.relationships.educations.data || [],
    getSpecialistExperience: (state: State) => state.specialistData?.attributes.experience,
    getSpecialistFirstName: (state: State) => state.specialistData?.attributes.firstName,
    getSpecialistsHelpingAdults: (state: State) => state.specialistData?.attributes.helpingAdults,
    getSpecialistsHelpingChildren: (state: State) => state.specialistData?.attributes.helpingChildren,
    getSpecialistLastName: (state: State) => state.specialistData?.attributes.lastName,
    getSpecialistPatronymic: (state: State) => state.specialistData?.attributes.patronymic,
    getSpecialistPriceFtfConsultation: (state: State) => state.specialistData?.attributes.priceFtfConsultation,
    getSpecialistPriceRemoteConsultation: (state: State) => state.specialistData?.attributes.priceRemoteConsultation,
    getSpecialistProfessionalSkills: (state: State) => state.specialistData?.relationships.professionalSkills.data || [],
    getSpecialistServices: (state: State) => state.specialistData?.relationships.services.data || [],
    getSpecialistSocialNetworks: (state: State) => state.specialistData?.relationships.socialNetworks.data || [],
    getSpecialistSpecialities: (state: State) => state.specialistData?.relationships.specialities.data || []
    // getSpecialistSpecializations: (state: State) => state.specialistData?.relationships.specializations.data || []
  },
  actions: {
    addAssociation (): void {
      this.form.associations.push({
        name: null
      })
    },
    addCourse (): void {
      this.form.courses.push({
        endDate: null,
        name: null,
        startDate: null
      })
    },
    addDocument (file: any, type: SpecialistDocumentTypeEnum): void {
      this.form.documents.push({
        id: null,
        file,
        url: URL.createObjectURL(file),
        type
      })
    },
    addEducation (): void {
      this.form.educations.push({
        endDate: null,
        name: null,
        organization: null,
        startDate: null
      })
    },
    addProfessionalSkill (): void {
      this.form.professionalSkills.push({
        description: null
      })
    },
    addService (): void {
      this.form.services.push({
        id: null,
        name: null,
        price: null
      })
    },
    addSpeciality (speciality: any): void {
      this.form.specialities.push(speciality)
    },
    addSocialNetwork (): void {
      this.form.socialNetworks.push({
        id: null,
        name: null,
        url: null
      })
    },
    // addSpecialization (specialization: any): void {
    //   this.form.specializations.push(specialization)
    // },
    clearFilter (): void {
      this.filter = {
        isForAdmin: 'no',
        // helpingAdults: false,
        // helpingChildren: false,
        priceTo: null,
        query: '',
        serviceId: null,
        specialityId: null
        // specializationId: null
      }
    },
    clearForm (): void {
      this.form.associations = []
      this.form.avatar = null
      this.form.avatarUrl = null
      this.form.courses = []
      this.form.deleteAvatar = false
      this.form.deleteAssociationIds = []
      this.form.deleteCourseIds = []
      this.form.deleteDocumentGroupIds = []
      this.form.deleteEducationIds = []
      this.form.deleteProfessionalSkillIds = []
      this.form.documents = []
      this.form.educations = []
      this.form.email = null
      this.form.experience = null
      this.form.firstName = null
      this.form.helpingAdults = true
      this.form.helpingChildren = true
      this.form.lastName = null
      this.form.link = null
      this.form.patronymic = null
      this.form.phoneNumber = null
      this.form.priceFtfConsultation = null
      this.form.priceRemoteConsultation = null
      this.form.professionalSkills = []
      this.form.services = []
      this.form.socialNetworks = []
      this.form.specialities = []
      this.form.updateAssociations = []
      this.form.updateCourses = []
      this.form.updateEducations = []
      this.form.updateProfessionalSkills = []
      this.form.user = null
      // this.form.specializations = []
    },
    clearFormApplication (): void {
      this.formApplication.adminComment = null
      this.formApplication.status = SpecialistApplicationStatus.InProcess
    },
    clearSpecialist (): void {
      this.specialistData = null
    },
    clearSpecialists (): void {
      this.specialistsData = []
    },
    dispose (): void {
      this.clearFilter()
      this.clearSpecialists()
    },
    dropAvatar (): void {
      if (this.specialistData) {
        this.specialistData.relationships.images.data = []
      }

      this.form.avatar = null
      this.form.deleteAvatar = true
    },
    dropAssociationForId (associationId: string): void {
      const index = this.form.associations.findIndex((x: any) => x.id === associationId)

      this.form.associations.splice(index, 1)

      this.form.deleteAssociationIds.push(associationId)
    },
    dropAssociationForIndex (associationIndex: number): void {
      this.form.associations.splice(associationIndex, 1)
    },
    dropCourseForId (courseId: string): void {
      const index = this.form.courses.findIndex((x: any) => x.id === courseId)

      this.form.courses.splice(index, 1)

      this.form.deleteCourseIds.push(courseId)
    },
    dropCourseForIndex (courseIndex: number): void {
      this.form.courses.splice(courseIndex, 1)
    },
    dropDocumentForIndex (documentIndex: number): void {
      this.form.documents.splice(documentIndex, 1)
    },
    dropDocumentGroupForId (documentGroupId: string): void {
      const index = this.specialistData?.relationships.documentGroups?.data.findIndex((x: any) => x.id === documentGroupId)

      if (index) {
        this.specialistData?.relationships.documentGroups?.data.splice(index, 1)
      }

      this.form.deleteDocumentGroupIds.push(documentGroupId)
    },
    dropEducationForId (educationId: string): void {
      const index = this.form.educations.findIndex((x: any) => x.id === educationId)

      this.form.educations.splice(index, 1)

      this.form.deleteEducationIds.push(educationId)
    },
    dropEducationForIndex (educationIndex: number): void {
      this.form.educations.splice(educationIndex, 1)
    },
    dropProfessionalSkillForId (professionalSkillId: string): void {
      const index = this.form.professionalSkills.findIndex((x: any) => x.id === professionalSkillId)

      this.form.professionalSkills.splice(index, 1)

      this.form.deleteProfessionalSkillIds.push(professionalSkillId)
    },
    dropProfessionalSkillForIndex (professionalSkillIndex: number): void {
      this.form.professionalSkills.splice(professionalSkillIndex, 1)
    },
    dropService (serviceIndex: number): void {
      this.form.services.splice(serviceIndex, 1)
    },
    dropSocialNetwork (socialNetworkIndex: number): void {
      this.form.socialNetworks.splice(socialNetworkIndex, 1)
    },
    dropSpeciality (specialityId: string): void {
      const index = this.form.specialities.findIndex((x: any) => x.id === specialityId)
      this.form.specialities.splice(index, 1)
    },
    // dropSpecialization (specializationId: string): void {
    //   const index = this.form.specializations.findIndex((x: any) => x.id === specializationId)
    //   this.form.specializations.splice(index, 1)
    // },
    setFormUpdatedData (): void {
      this.form.updateAssociations = []
      this.form.updateCourses = []
      this.form.updateEducations = []
      this.form.updateProfessionalSkills = []

      if (this.specialistData) {
        this.form.associations.filter(
          (x: any) => x.changed !== undefined && x.changed === true && x.id
        ).forEach((x: any) => {
          this.form.updateAssociations.push({
            id: x.id,
            name: x.name
          })
        })

        this.form.courses.filter(
          (x: any) => x.changed !== undefined && x.changed === true && x.id
        ).forEach((x: any) => {
          this.form.updateCourses.push({
            id: x.id,
            endDate: x.endDate,
            name: x.name,
            startDate: x.startDate
          })
        })

        this.form.educations.filter(
          (x: any) => x.changed !== undefined && x.changed === true && x.id
        ).forEach((x: any) => {
          this.form.updateEducations.push({
            id: x.id,
            endDate: x.endDate,
            name: x.name,
            organization: x.organization,
            startDate: x.startDate
          })
        })

        this.form.professionalSkills.filter(
          (x: any) => x.changed !== undefined && x.changed === true && x.id
        ).forEach((x: any) => {
          this.form.updateProfessionalSkills.push({ id: x.id, description: x.description })
        })
      }
    },
    specialistToForm (): void {
      if (this.specialistData) {
        this.clearForm()

        this.form.avatarUrl = this.specialistData.attributes.avatarUrl
        this.form.email = this.specialistData.attributes.email
        this.form.experience = this.specialistData.attributes.experience
        this.form.firstName = this.specialistData.attributes.firstName
        this.form.helpingAdults = this.specialistData.attributes.helpingAdults
        this.form.helpingChildren = this.specialistData.attributes.helpingChildren
        this.form.lastName = this.specialistData.attributes.lastName
        this.form.link = this.specialistData.attributes.link
        this.form.patronymic = this.specialistData.attributes.patronymic
        this.form.phoneNumber = this.specialistData.attributes.phoneNumber
        this.form.priceFtfConsultation = this.specialistData.attributes.priceFtfConsultation
        this.form.priceRemoteConsultation = this.specialistData.attributes.priceRemoteConsultation

        this.form.associations = this.specialistData.relationships.associations.data.map((x: any): any => ({
          id: x.id,
          name: x.attributes.name
        }))

        this.form.courses = this.specialistData.relationships.courses.data.map((x: any): any => ({
          id: x.id,
          endDate: x.attributes.endDate,
          name: x.attributes.name,
          startDate: x.attributes.startDate
        }))

        this.specialistData.relationships.documentGroups?.data.forEach((group: any) => {
          const document = group.relationships.documents.data.find((document: any) => document.meta.type === '100x100')

          this.form.documents.push({
            id: group.id,
            file: null,
            url: document.meta.url,
            type: document.meta.documentType
          })
        })

        this.form.educations = this.specialistData.relationships.educations.data.map((x: any): any => ({
          id: x.id,
          endDate: x.attributes.endDate,
          name: x.attributes.name,
          organization: x.attributes.organization,
          startDate: x.attributes.startDate
        }))

        this.form.professionalSkills = this.specialistData.relationships.professionalSkills.data.map((x: any): any => ({
          id: x.id,
          description: x.attributes.description
        }))

        this.form.services = this.specialistData.relationships.services.data.map((x: any): any => ({
          id: x.id,
          name: x.attributes.name,
          price: x.attributes.price
        }))

        this.form.socialNetworks = this.specialistData.relationships.socialNetworks.data.map((x: any): any => ({
          id: x.id,
          name: x.attributes.name,
          url: x.attributes.url
        }))

        this.form.specialities = this.specialistData.relationships.specialities.data.map((x: any): any => ({
          id: x.id,
          name: x.attributes.name
        }))

        // this.form.specializations = this.specialistData.relationships.specializations.data.map((x: any): any => ({
        //   id: x.id,
        //   name: x.attributes.name
        // }))

        if (this.specialistData.relationships.user?.data) {
          this.form.user = this.specialistData.relationships.user.data
        }
      }
    },
    updateAssociation (associationId: null | string | undefined): void {
      if (associationId) {
        this.form.associations.find((x: any) => x.id === associationId).changed = true
      }
    },
    updateCourse (courseId: null | string | undefined): void {
      if (courseId) {
        this.form.courses.find((x: any) => x.id === courseId).changed = true
      }
    },
    updateEducation (educationId: null | string | undefined): void {
      if (educationId) {
        this.form.educations.find((x: any) => x.id === educationId).changed = true
      }
    },
    updateProfessionalSkill (professionalSkillId: null | string | undefined): void {
      if (professionalSkillId) {
        this.form.professionalSkills.find((x: any) => x.id === professionalSkillId).changed = true
      }
    },
    validate (): boolean {
      const { $toast } = useNuxtApp()

      if (!this.form.firstName) {
        $toast.error(messages.firstNameFieldCannotBeEmpty)

        return false
      }

      if (!this.form.lastName) {
        $toast.error(messages.lastNameFieldCannotBeEmpty)

        return false
      }

      if (!this.form.email) {
        $toast.error(messages.emailFieldCannotBeEmpty)

        return false
      }

      if (!this.form.phoneNumber) {
        $toast.error(messages.phoneNumberFieldCannotBeEmpty)

        return false
      }

      if (!this.form.experience) {
        $toast.error(messages.experienceFieldCannotBeEmpty)

        return false
      }

      return true
    },
    async createSpecialist (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()
      const router = useRouter()
      const userStore = useUserStore()

      if (!this.validate()) {
        return
      }

      this.isSaving = true

      const formData = new FormData()

      if (this.form.avatar?.file) {
        formData.append('avatar', this.form.avatar.file)
      }

      formData.append('email', this.form.email ?? '')
      formData.append('experience', this.form.experience ?? '')
      formData.append('firstName', this.form.firstName)
      formData.append('helpingAdults', this.form.helpingAdults ? '1' : '0')
      formData.append('helpingChildren', this.form.helpingChildren ? '1' : '0')
      formData.append('lastName', this.form.lastName ?? '')
      formData.append('link', this.form.link ?? '')
      formData.append('patronymic', this.form.patronymic ?? '')
      formData.append('phoneNumber', this.form.phoneNumber ?? '')
      formData.append('priceFtfConsultation', this.form.priceFtfConsultation ?? '')
      formData.append('priceRemoteConsultation', this.form.priceRemoteConsultation ?? '')
      formData.append('userId', this.form.user ? this.form.user.id : '')

      const documents = this.form.documents.filter((x: any) => !x.id)
      const specialityIds = this.form.specialities.map((x: any) => x.id)
      // const specializationIds = this.form.specializations.map((x: any) => x.id)

      for (let i = 0; i < this.form.associations.length; i++) {
        formData.append('associations[][name]', this.form.associations[i].name ?? '')
      }

      for (let i = 0; i < this.form.courses.length; i++) {
        formData.append(`courses[${i}][endDate]`, `${this.form.courses[i].endDate}-01` ?? '')
        formData.append(`courses[${i}][name]`, this.form.courses[i].name ?? '')
        formData.append(`courses[${i}][startDate]`, `${this.form.courses[i].startDate}-01` ?? '')
      }

      for (let i = 0; i < this.form.educations.length; i++) {
        formData.append(`educations[${i}][endDate]`, this.form.educations[i].endDate ?? '')
        formData.append(`educations[${i}][name]`, this.form.educations[i].name ?? '')
        formData.append(`educations[${i}][organization]`, this.form.educations[i].organization ?? '')
        formData.append(`educations[${i}][startDate]`, this.form.educations[i].startDate ?? '')
      }

      for (let i = 0; i < documents.length; i++) {
        formData.append(`documents[${i}][image]`, documents[i].file)
        formData.append(`documents[${i}][type]`, documents[i].type)
      }

      for (let i = 0; i < this.form.professionalSkills.length; i++) {
        if (this.form.professionalSkills[i].description !== null && this.form.professionalSkills[i].description !== '') {
          formData.append('professionalSkills[][description]', this.form.professionalSkills[i].description)
        }
      }

      for (let i = 0; i < this.form.services.length; i++) {
        formData.append(`services[${i}][id]`, this.form.services[i].id ?? '')
        formData.append(`services[${i}][name]`, this.form.services[i].name ?? '')
        formData.append(`services[${i}][price]`, this.form.services[i].price ?? '')
      }

      for (let i = 0; i < this.form.socialNetworks.length; i++) {
        formData.append(`socialNetworks[${i}][id]`, this.form.socialNetworks[i].id ?? '')
        formData.append(`socialNetworks[${i}][name]`, this.form.socialNetworks[i].name ?? '')
        formData.append(`socialNetworks[${i}][url]`, this.form.socialNetworks[i].url ?? '')
      }

      for (let i = 0; i < specialityIds.length; i++) {
        formData.append('specialityIds[]', specialityIds[i])
      }

      // for (let i = 0; i < specializationIds.length; i++) {
      //   formData.append('specializationIds[]', specializationIds[i])
      // }

      try {
        const response = await $apiHelper.specialists.createSpecialist(formData) as any

        if (userStore.hasAccessToAdminPanel) {
          const { data } = JsonApiHelper.denormalizeResponse(response) as any
          router.push(PageEnum.SpecialistAdmin + data.id)
        } else {
          router.push(PageEnum.Main)
        }

        setTimeout(() => {
          $toast.success(messages.specialistIsCreated)
        }, 100)
      } catch (error) {
        console.error(error)

        ApiErrorHandler.handleFetchError(error as NuxtError, messages.cannotCreateSpecialist)

        this.isSaving = false

        throw error
      }

      this.isSaving = false
      this.hasUnsavedChanges = false
    },
    async deleteSpecialist (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        await $apiHelper.specialists.deleteSpecialist(specialistId) as any

        const specialistIndex = this.specialistsData.findIndex((x: SpecialistData) => x.id === specialistId)
        this.specialistsData.splice(specialistIndex, 1)

        $toast.success(messages.specialistIsDeleted)
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotDeleteSpecialist)

        throw error
      }
    },
    async fetchSpecialist (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.specialists.getSpecialist(specialistId) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        this.specialistData = data
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotGetSpecialist)

        throw error
      }
    },
    async fetchSpecialistApplications (): Promise<void> {
      if (!this.specialistData) {
        return
      }

      this.isApplicationDataLoading = true

      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.specialists.getSpecialistApplications(this.specialistData.id) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        if (this.specialistData) {
          this.specialistData.relationships.applications = { data }
        }

        this.isApplicationDataLoading = false
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotGetSpecialistApplications)

        throw error
      }
    },
    async fetchSpecialistDocumentGroups (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.specialists.getSpecialistDocumentGroups(specialistId) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        if (this.specialistData) {
          this.specialistData.relationships.documentGroups = { data }
        }
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotGetSpecialistDocumentGroups)

        throw error
      }
    },
    async fetchSpecialistImages (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.specialists.getSpecialistImages(specialistId) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        if (this.specialistData) {
          this.specialistData.relationships.images = { data }
        }
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotGetSpecialistImages)

        throw error
      }
    },
    async fetchSpecialists (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isDataLoading = true
      this.totalPages = null

      try {
        const response = await $apiHelper.specialists.getSpecialists({ filter: this.filter, page: this.page }) as any
        const { data, meta } = JsonApiHelper.denormalizeResponse(response) as any

        this.totalSpecialists = meta.totalSpecialists
        this.totalPages = helpers.getTotalPages(meta.totalSpecialists, this.page.size)

        this.specialistsData = data

        this.isDataLoading = false
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotGetSpecialists)

        throw error
      }
    },
    async updateSpecialist (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.setFormUpdatedData()

      if (!this.validate()) {
        return
      }

      this.isSaving = true

      const formData = new FormData()

      if (this.form.avatar?.file) {
        formData.append('avatar', this.form.avatar.file)
      }

      formData.append('deleteAvatar', this.form.deleteAvatar ? '1' : '0')
      formData.append('email', this.form.email ?? '')
      formData.append('experience', this.form.experience ?? '')
      formData.append('firstName', this.form.firstName)
      formData.append('helpingAdults', this.form.helpingAdults ? '1' : '0')
      formData.append('helpingChildren', this.form.helpingChildren ? '1' : '0')
      formData.append('lastName', this.form.lastName ?? '')
      formData.append('link', this.form.link ?? '')
      formData.append('patronymic', this.form.patronymic ?? '')
      formData.append('phoneNumber', this.form.phoneNumber ?? '')
      formData.append('priceFtfConsultation', this.form.priceFtfConsultation ?? '')
      formData.append('priceRemoteConsultation', this.form.priceRemoteConsultation ?? '')
      formData.append('userId', this.form.user ? this.form.user.id : '')

      const associations = this.form.associations.filter((x: any) => !x.id)
      const courses = this.form.courses.filter((x: any) => !x.id)
      const documents = this.form.documents.filter((x: any) => !x.id)
      const educations = this.form.educations.filter((x: any) => !x.id)
      const professionalSkills = this.form.professionalSkills.filter((x: any) => !x.id)
      const specialityIds = this.form.specialities.map((x: any) => x.id)
      // const specializationIds = this.form.specializations.map((x: any) => x.id)

      for (let i = 0; i < associations.length; i++) {
        formData.append('associations[][name]', associations[i].name ?? '')
      }

      for (let i = 0; i < courses.length; i++) {
        formData.append(`courses[${i}][endDate]`, `${courses[i].endDate}-01` ?? '')
        formData.append(`courses[${i}][name]`, courses[i].name ?? '')
        formData.append(`courses[${i}][startDate]`, `${courses[i].startDate}-01` ?? '')
      }

      for (let i = 0; i < this.form.deleteAssociationIds.length; i++) {
        formData.append('deleteAssociationIds[]', this.form.deleteAssociationIds[i])
      }

      for (let i = 0; i < this.form.deleteCourseIds.length; i++) {
        formData.append('deleteCourseIds[]', this.form.deleteCourseIds[i])
      }

      for (let i = 0; i < this.form.deleteDocumentGroupIds.length; i++) {
        formData.append('deleteDocumentGroupIds[]', this.form.deleteDocumentGroupIds[i])
      }

      for (let i = 0; i < this.form.deleteEducationIds.length; i++) {
        formData.append('deleteEducationIds[]', this.form.deleteEducationIds[i])
      }

      for (let i = 0; i < this.form.deleteProfessionalSkillIds.length; i++) {
        formData.append('deleteProfessionalSkillIds[]', this.form.deleteProfessionalSkillIds[i])
      }

      for (let i = 0; i < documents.length; i++) {
        formData.append(`documents[${i}][image]`, documents[i].file)
        formData.append(`documents[${i}][type]`, documents[i].type)
      }

      for (let i = 0; i < educations.length; i++) {
        formData.append(`educations[${i}][endDate]`, educations[i].endDate ?? '')
        formData.append(`educations[${i}][name]`, educations[i].name ?? '')
        formData.append(`educations[${i}][organization]`, educations[i].organization ?? '')
        formData.append(`educations[${i}][startDate]`, educations[i].startDate ?? '')
      }

      for (let i = 0; i < professionalSkills.length; i++) {
        if (professionalSkills[i].description !== null && professionalSkills[i].description !== '') {
          formData.append('professionalSkills[][description]', professionalSkills[i].description)
        }
      }

      if (this.form.services.length) {
        for (let i = 0; i < this.form.services.length; i++) {
          formData.append(`services[${i}][id]`, this.form.services[i].id ?? '')
          formData.append(`services[${i}][name]`, this.form.services[i].name ?? '')
          formData.append(`services[${i}][price]`, this.form.services[i].price ?? '')
        }
      } else {
        formData.append('services', '')
      }

      if (this.form.socialNetworks.length) {
        for (let i = 0; i < this.form.socialNetworks.length; i++) {
          formData.append(`socialNetworks[${i}][id]`, this.form.socialNetworks[i].id ?? '')
          formData.append(`socialNetworks[${i}][name]`, this.form.socialNetworks[i].name ?? '')
          formData.append(`socialNetworks[${i}][url]`, this.form.socialNetworks[i].url ?? '')
        }
      } else {
        formData.append('socialNetworks', '')
      }

      if (specialityIds.length) {
        for (let i = 0; i < specialityIds.length; i++) {
          formData.append('specialityIds[]', specialityIds[i])
        }
      } else {
        formData.append('specialityIds', '')
      }

      for (let i = 0; i < this.form.updateAssociations.length; i++) {
        formData.append(`updateAssociations[${i}][id]`, this.form.updateAssociations[i].id ?? '')
        formData.append(`updateAssociations[${i}][name]`, this.form.updateAssociations[i].name ?? '')
      }

      for (let i = 0; i < this.form.updateCourses.length; i++) {
        formData.append(`updateCourses[${i}][id]`, this.form.updateCourses[i].id ?? '')
        formData.append(`updateCourses[${i}][endDate]`, `${this.form.updateCourses[i].endDate}-01` ?? '')
        formData.append(`updateCourses[${i}][name]`, this.form.updateCourses[i].name ?? '')
        formData.append(`updateCourses[${i}][startDate]`, `${this.form.updateCourses[i].startDate}-01` ?? '')
      }

      for (let i = 0; i < this.form.updateEducations.length; i++) {
        formData.append(`updateEducations[${i}][id]`, this.form.updateEducations[i].id ?? '')
        formData.append(`updateEducations[${i}][endDate]`, this.form.updateEducations[i].endDate ?? '')
        formData.append(`updateEducations[${i}][name]`, this.form.updateEducations[i].name ?? '')
        formData.append(`updateEducations[${i}][organization]`, this.form.updateEducations[i].organization ?? '')
        formData.append(`updateEducations[${i}][startDate]`, this.form.updateEducations[i].startDate ?? '')
      }

      for (let i = 0; i < this.form.updateProfessionalSkills.length; i++) {
        if (this.form.updateProfessionalSkills[i].description !== null && this.form.updateProfessionalSkills[i].description !== '') {
          formData.append(`updateProfessionalSkills[${i}][id]`, this.form.updateProfessionalSkills[i].id ?? '')
          formData.append(`updateProfessionalSkills[${i}][description]`, this.form.updateProfessionalSkills[i].description ?? '')
        }
      }

      // for (let i = 0; i < specializationIds.length; i++) {
      //   formData.append('specializationIds[]', specializationIds[i])
      // }

      try {
        const response = await $apiHelper.specialists.updateSpecialist(specialistId, formData) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        this.specialistData = data

        $toast.success(messages.specialistIsSaved)
      } catch (error) {
        console.error(error)

        ApiErrorHandler.handleFetchError(error as NuxtError, messages.cannotUpdateSpecialist)

        this.isSaving = false

        throw error
      }

      this.isSaving = false
      this.hasUnsavedChanges = false
    },
    async updateSpecialistApplication (specialistId: string) {
      const { $apiHelper, $toast } = useNuxtApp()
      const router = useRouter()

      try {
        await $apiHelper.specialists.updateSpecialistApplication(specialistId, this.formApplication) as any

        router.push(PageEnum.AdminSpecialists)

        setTimeout(() => {
          $toast.success(messages.specialistApplicationIsSaved)
        }, 100)
      } catch (error) {
        console.error(error)

        $toast.error(messages.cannotUpdateSpecialistApplication)

        throw error
      }
    }
  }
})
