import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Products extends HttpFactory {
  private resource = 'v1/products'

  public createProduct (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getProduct (productId: string, params: object = {}) {
    const { guestId, token } = this.authStore
    const config = { params }

    if (token) {
      (config as any).headers = getAuthHeaders(token)
    } else if (guestId) {
      (config.params as any).guestId = guestId
    }

    return this.$fetch(`${this.resource}/${productId}`, config)
  }

  public getProducts (params: object) {
    const { guestId, token } = this.authStore
    const config = { params }

    if (token) {
      (config as any).headers = getAuthHeaders(token)
    } else if (guestId) {
      (config.params as any).guestId = guestId
    }

    return this.$fetch(this.resource, config)
  }

  public updateProduct (productId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${productId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteProduct (productId: string) {
    return this.$fetch(
      `${this.resource}/${productId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
