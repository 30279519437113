import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Lists extends HttpFactory {
  private resource = 'v1/lists'

  public createList (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getList (listId: string) {
    return this.$fetch(`${this.resource}/${listId}`, { headers: getAuthHeaders(this.authStore.token) })
  }

  public getLists (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateList (listId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${listId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteList (listId: string) {
    return this.$fetch(
      `${this.resource}/${listId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public createListProduct (listId: string, productId?: string, productIds?: string[]) {
    return this.$fetch(
      `${this.resource}/${listId}/relationships/products`,
      {
        method: 'POST',
        body: JSON.stringify(productId ? { productId } : { productIds }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteListProduct (listId: string, productId: string) {
    return this.$fetch(
      `${this.resource}/${listId}/relationships/products/${productId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
