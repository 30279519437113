import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Articles extends HttpFactory {
  private resource = 'v1/articles'

  public createArticle (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getArticle (articleId: string, params?: object) {
    return this.$fetch(
      `${this.resource}/${articleId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getArticles (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateArticle (articleId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${articleId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteArticle (articleId: string) {
    return this.$fetch(
      `${this.resource}/${articleId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }
}
