import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as addresses1USOpCZ7cbMeta } from "/usr/src/app/pages/account/addresses.vue?macro=true";
import { default as consultations0ybDvSmwtWMeta } from "/usr/src/app/pages/account/consultations.vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as listsgNGSFQ8yKGMeta } from "/usr/src/app/pages/account/lists.vue?macro=true";
import { default as ordersVRYxFTVlPnMeta } from "/usr/src/app/pages/account/orders.vue?macro=true";
import { default as recommendationsTjHWb89FFdMeta } from "/usr/src/app/pages/account/recommendations.vue?macro=true";
import { default as settings4DJbRzkKKtMeta } from "/usr/src/app/pages/account/settings.vue?macro=true";
import { default as test_45resultsyzAAbOq63OMeta } from "/usr/src/app/pages/account/test-results.vue?macro=true";
import { default as _91action_93RyltZgzqARMeta } from "/usr/src/app/pages/actions/[action].vue?macro=true";
import { default as ambassadorsPJrJF49AGWMeta } from "/usr/src/app/pages/admin/ambassadors.vue?macro=true";
import { default as analyticseofTjBoBMoMeta } from "/usr/src/app/pages/admin/analytics.vue?macro=true";
import { default as articlesKARfxnZl2fMeta } from "/usr/src/app/pages/admin/articles.vue?macro=true";
import { default as brandsoFmkoJenbGMeta } from "/usr/src/app/pages/admin/brands.vue?macro=true";
import { default as categoriesWge3nJQZVGMeta } from "/usr/src/app/pages/admin/categories.vue?macro=true";
import { default as certificates6CzhVkcMSRMeta } from "/usr/src/app/pages/admin/certificates.vue?macro=true";
import { default as collections87aDl9qic6Meta } from "/usr/src/app/pages/admin/collections.vue?macro=true";
import { default as consultationsLNfA1uXHICMeta } from "/usr/src/app/pages/admin/consultations.vue?macro=true";
import { default as dashboardgDRV9tihisMeta } from "/usr/src/app/pages/admin/dashboard.vue?macro=true";
import { default as indexgKB99wg3ORMeta } from "/usr/src/app/pages/admin/index.vue?macro=true";
import { default as messagesJUBNXeCztLMeta } from "/usr/src/app/pages/admin/messages.vue?macro=true";
import { default as ordersMTyBp9uPENMeta } from "/usr/src/app/pages/admin/orders.vue?macro=true";
import { default as productsPBhKoJLmRBMeta } from "/usr/src/app/pages/admin/products.vue?macro=true";
import { default as promo_45code_45applicationsIXEgaSjPX5Meta } from "/usr/src/app/pages/admin/promo-code-applications.vue?macro=true";
import { default as promo_45codesqxeZF5E6B4Meta } from "/usr/src/app/pages/admin/promo-codes.vue?macro=true";
import { default as quality_45certificates3pxTT8YjpdMeta } from "/usr/src/app/pages/admin/quality-certificates.vue?macro=true";
import { default as reviewsq3fph56jQjMeta } from "/usr/src/app/pages/admin/reviews.vue?macro=true";
import { default as scriptsIoShoDZcRJMeta } from "/usr/src/app/pages/admin/scripts.vue?macro=true";
import { default as special_45offersKdMuRV3nk7Meta } from "/usr/src/app/pages/admin/special-offers.vue?macro=true";
import { default as _91id_938zHDCDgbMRMeta } from "/usr/src/app/pages/admin/specialist/[id].vue?macro=true";
import { default as createZy6KQ3bkk5Meta } from "/usr/src/app/pages/admin/specialist/create.vue?macro=true";
import { default as specialistsieuiaEUExuMeta } from "/usr/src/app/pages/admin/specialists.vue?macro=true";
import { default as supplier_45applicationsAVV3gYKArmMeta } from "/usr/src/app/pages/admin/supplier-applications.vue?macro=true";
import { default as supplier_45faqsNZPhzKGRIVMeta } from "/usr/src/app/pages/admin/supplier-faqs.vue?macro=true";
import { default as suppliersjwpUU3bqTcMeta } from "/usr/src/app/pages/admin/suppliers.vue?macro=true";
import { default as tags3FpEwQXXTkMeta } from "/usr/src/app/pages/admin/tags.vue?macro=true";
import { default as testsWDgtFEq4PGMeta } from "/usr/src/app/pages/admin/tests.vue?macro=true";
import { default as usersbh6Hk8SLbpMeta } from "/usr/src/app/pages/admin/users.vue?macro=true";
import { default as warehouseswgPCeRv2tfMeta } from "/usr/src/app/pages/admin/warehouses.vue?macro=true";
import { default as withdrawalsEks5UgA7CxMeta } from "/usr/src/app/pages/admin/withdrawals.vue?macro=true";
import { default as _91slugAndId_938qsE8jSKO9Meta } from "/usr/src/app/pages/articles/[slugAndId].vue?macro=true";
import { default as indexWgIXlS0zz8Meta } from "/usr/src/app/pages/articles/index.vue?macro=true";
import { default as _91slugAndId_93sRRgMIEythMeta } from "/usr/src/app/pages/brands/[slugAndId].vue?macro=true";
import { default as calltouchRMaWxi3CAgMeta } from "/usr/src/app/pages/calltouch.vue?macro=true";
import { default as cart37DfDFSHEiMeta } from "/usr/src/app/pages/cart.vue?macro=true";
import { default as _91slugAndId_93avBaO2r0hJMeta } from "/usr/src/app/pages/categories/[slugAndId].vue?macro=true";
import { default as _91slugAndId_938gTeTi6Q6aMeta } from "/usr/src/app/pages/collections/[slugAndId].vue?macro=true";
import { default as indexLA1BcSgSMNMeta } from "/usr/src/app/pages/collections/index.vue?macro=true";
import { default as contactsecK6rf8N8MMeta } from "/usr/src/app/pages/contacts.vue?macro=true";
import { default as debug8qnrjpLwmWMeta } from "/usr/src/app/pages/debug.vue?macro=true";
import { default as deliveryrXgyikQPYkMeta } from "/usr/src/app/pages/delivery.vue?macro=true";
import { default as distributorpaeFjRFXnOMeta } from "/usr/src/app/pages/distributor.vue?macro=true";
import { default as expert_45medical_45commissionPADCzMVJXCMeta } from "/usr/src/app/pages/expert-medical-commission.vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as favoritesSlWwpeyEBwMeta } from "/usr/src/app/pages/favorites.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91id_93xOdFhjdipkMeta } from "/usr/src/app/pages/lists/[id].vue?macro=true";
import { default as partner7hKr3ksOFgMeta } from "/usr/src/app/pages/partner.vue?macro=true";
import { default as paymentWU6xDFfrpIMeta } from "/usr/src/app/pages/payment.vue?macro=true";
import { default as _91slugAndId_933QfINipTevMeta } from "/usr/src/app/pages/products/[slugAndId].vue?macro=true";
import { default as indextGTcZpyi4NMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as returnXBeP2vdzalMeta } from "/usr/src/app/pages/return.vue?macro=true";
import { default as _91slugAndId_93umV98oYmpWMeta } from "/usr/src/app/pages/special-offers/[slugAndId].vue?macro=true";
import { default as indexfSSaA0dsKAMeta } from "/usr/src/app/pages/special-offers/index.vue?macro=true";
import { default as consultationsVou9MNxkVmMeta } from "/usr/src/app/pages/specialist/consultations.vue?macro=true";
import { default as createez9htmVEQDMeta } from "/usr/src/app/pages/specialist/create.vue?macro=true";
import { default as indexbBRU1vBLPlMeta } from "/usr/src/app/pages/specialist/index.vue?macro=true";
import { default as profileEaXWDZe0gwMeta } from "/usr/src/app/pages/specialist/profile.vue?macro=true";
import { default as recommendations4p5GJt2ZOlMeta } from "/usr/src/app/pages/specialist/recommendations.vue?macro=true";
import { default as _91id_93gmrTCVfmVeMeta } from "/usr/src/app/pages/specialists/[id].vue?macro=true";
import { default as indexBFpXcu7b0dMeta } from "/usr/src/app/pages/specialists/index.vue?macro=true";
import { default as analyticsk9GN0r2UIYMeta } from "/usr/src/app/pages/supplier/analytics.vue?macro=true";
import { default as brandsp1mDkJCQbeMeta } from "/usr/src/app/pages/supplier/brands.vue?macro=true";
import { default as indexVet8AcjlbjMeta } from "/usr/src/app/pages/supplier/index.vue?macro=true";
import { default as products0NGSgFvas5Meta } from "/usr/src/app/pages/supplier/products.vue?macro=true";
import { default as _91slugAndId_93OCunHoJro6Meta } from "/usr/src/app/pages/tests/[slugAndId].vue?macro=true";
import { default as index89CTA1sZRbMeta } from "/usr/src/app/pages/tests/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    component: () => import("/usr/src/app/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-consultations",
    path: "/account/consultations",
    component: () => import("/usr/src/app/pages/account/consultations.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lists",
    path: "/account/lists",
    component: () => import("/usr/src/app/pages/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "/account/orders",
    component: () => import("/usr/src/app/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-recommendations",
    path: "/account/recommendations",
    component: () => import("/usr/src/app/pages/account/recommendations.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    component: () => import("/usr/src/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-test-results",
    path: "/account/test-results",
    component: () => import("/usr/src/app/pages/account/test-results.vue").then(m => m.default || m)
  },
  {
    name: "actions-action",
    path: "/actions/:action()",
    component: () => import("/usr/src/app/pages/actions/[action].vue").then(m => m.default || m)
  },
  {
    name: "admin-ambassadors",
    path: "/admin/ambassadors",
    component: () => import("/usr/src/app/pages/admin/ambassadors.vue").then(m => m.default || m)
  },
  {
    name: "admin-analytics",
    path: "/admin/analytics",
    component: () => import("/usr/src/app/pages/admin/analytics.vue").then(m => m.default || m)
  },
  {
    name: "admin-articles",
    path: "/admin/articles",
    component: () => import("/usr/src/app/pages/admin/articles.vue").then(m => m.default || m)
  },
  {
    name: "admin-brands",
    path: "/admin/brands",
    component: () => import("/usr/src/app/pages/admin/brands.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories",
    path: "/admin/categories",
    component: () => import("/usr/src/app/pages/admin/categories.vue").then(m => m.default || m)
  },
  {
    name: "admin-certificates",
    path: "/admin/certificates",
    component: () => import("/usr/src/app/pages/admin/certificates.vue").then(m => m.default || m)
  },
  {
    name: "admin-collections",
    path: "/admin/collections",
    component: () => import("/usr/src/app/pages/admin/collections.vue").then(m => m.default || m)
  },
  {
    name: "admin-consultations",
    path: "/admin/consultations",
    component: () => import("/usr/src/app/pages/admin/consultations.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    component: () => import("/usr/src/app/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/usr/src/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-messages",
    path: "/admin/messages",
    component: () => import("/usr/src/app/pages/admin/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    component: () => import("/usr/src/app/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: "admin-products",
    path: "/admin/products",
    component: () => import("/usr/src/app/pages/admin/products.vue").then(m => m.default || m)
  },
  {
    name: "admin-promo-code-applications",
    path: "/admin/promo-code-applications",
    component: () => import("/usr/src/app/pages/admin/promo-code-applications.vue").then(m => m.default || m)
  },
  {
    name: "admin-promo-codes",
    path: "/admin/promo-codes",
    component: () => import("/usr/src/app/pages/admin/promo-codes.vue").then(m => m.default || m)
  },
  {
    name: "admin-quality-certificates",
    path: "/admin/quality-certificates",
    component: () => import("/usr/src/app/pages/admin/quality-certificates.vue").then(m => m.default || m)
  },
  {
    name: "admin-reviews",
    path: "/admin/reviews",
    component: () => import("/usr/src/app/pages/admin/reviews.vue").then(m => m.default || m)
  },
  {
    name: "admin-scripts",
    path: "/admin/scripts",
    component: () => import("/usr/src/app/pages/admin/scripts.vue").then(m => m.default || m)
  },
  {
    name: "admin-special-offers",
    path: "/admin/special-offers",
    component: () => import("/usr/src/app/pages/admin/special-offers.vue").then(m => m.default || m)
  },
  {
    name: "admin-specialist-id",
    path: "/admin/specialist/:id()",
    component: () => import("/usr/src/app/pages/admin/specialist/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-specialist-create",
    path: "/admin/specialist/create",
    component: () => import("/usr/src/app/pages/admin/specialist/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-specialists",
    path: "/admin/specialists",
    component: () => import("/usr/src/app/pages/admin/specialists.vue").then(m => m.default || m)
  },
  {
    name: "admin-supplier-applications",
    path: "/admin/supplier-applications",
    component: () => import("/usr/src/app/pages/admin/supplier-applications.vue").then(m => m.default || m)
  },
  {
    name: "admin-supplier-faqs",
    path: "/admin/supplier-faqs",
    component: () => import("/usr/src/app/pages/admin/supplier-faqs.vue").then(m => m.default || m)
  },
  {
    name: "admin-suppliers",
    path: "/admin/suppliers",
    component: () => import("/usr/src/app/pages/admin/suppliers.vue").then(m => m.default || m)
  },
  {
    name: "admin-tags",
    path: "/admin/tags",
    component: () => import("/usr/src/app/pages/admin/tags.vue").then(m => m.default || m)
  },
  {
    name: "admin-tests",
    path: "/admin/tests",
    component: () => import("/usr/src/app/pages/admin/tests.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/usr/src/app/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-warehouses",
    path: "/admin/warehouses",
    component: () => import("/usr/src/app/pages/admin/warehouses.vue").then(m => m.default || m)
  },
  {
    name: "admin-withdrawals",
    path: "/admin/withdrawals",
    component: () => import("/usr/src/app/pages/admin/withdrawals.vue").then(m => m.default || m)
  },
  {
    name: "articles-slugAndId",
    path: "/articles/:slugAndId()",
    component: () => import("/usr/src/app/pages/articles/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/usr/src/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-slugAndId",
    path: "/brands/:slugAndId()",
    component: () => import("/usr/src/app/pages/brands/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "calltouch",
    path: "/calltouch",
    component: () => import("/usr/src/app/pages/calltouch.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/usr/src/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "categories-slugAndId",
    path: "/categories/:slugAndId()",
    component: () => import("/usr/src/app/pages/categories/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "collections-slugAndId",
    path: "/collections/:slugAndId()",
    component: () => import("/usr/src/app/pages/collections/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/usr/src/app/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/usr/src/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "debug",
    path: "/debug",
    component: () => import("/usr/src/app/pages/debug.vue").then(m => m.default || m)
  },
  {
    name: "delivery",
    path: "/delivery",
    component: () => import("/usr/src/app/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "distributor",
    path: "/distributor",
    component: () => import("/usr/src/app/pages/distributor.vue").then(m => m.default || m)
  },
  {
    name: "expert-medical-commission",
    path: "/expert-medical-commission",
    component: () => import("/usr/src/app/pages/expert-medical-commission.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/usr/src/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lists-id",
    path: "/lists/:id()",
    component: () => import("/usr/src/app/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner",
    path: "/partner",
    component: () => import("/usr/src/app/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/usr/src/app/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: "products-slugAndId",
    path: "/products/:slugAndId()",
    component: () => import("/usr/src/app/pages/products/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/usr/src/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "return",
    path: "/return",
    component: () => import("/usr/src/app/pages/return.vue").then(m => m.default || m)
  },
  {
    name: "special-offers-slugAndId",
    path: "/special-offers/:slugAndId()",
    component: () => import("/usr/src/app/pages/special-offers/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "special-offers",
    path: "/special-offers",
    component: () => import("/usr/src/app/pages/special-offers/index.vue").then(m => m.default || m)
  },
  {
    name: "specialist-consultations",
    path: "/specialist/consultations",
    component: () => import("/usr/src/app/pages/specialist/consultations.vue").then(m => m.default || m)
  },
  {
    name: "specialist-create",
    path: "/specialist/create",
    component: () => import("/usr/src/app/pages/specialist/create.vue").then(m => m.default || m)
  },
  {
    name: "specialist",
    path: "/specialist",
    component: () => import("/usr/src/app/pages/specialist/index.vue").then(m => m.default || m)
  },
  {
    name: "specialist-profile",
    path: "/specialist/profile",
    component: () => import("/usr/src/app/pages/specialist/profile.vue").then(m => m.default || m)
  },
  {
    name: "specialist-recommendations",
    path: "/specialist/recommendations",
    component: () => import("/usr/src/app/pages/specialist/recommendations.vue").then(m => m.default || m)
  },
  {
    name: "specialists-id",
    path: "/specialists/:id()",
    component: () => import("/usr/src/app/pages/specialists/[id].vue").then(m => m.default || m)
  },
  {
    name: "specialists",
    path: "/specialists",
    component: () => import("/usr/src/app/pages/specialists/index.vue").then(m => m.default || m)
  },
  {
    name: "supplier-analytics",
    path: "/supplier/analytics",
    component: () => import("/usr/src/app/pages/supplier/analytics.vue").then(m => m.default || m)
  },
  {
    name: "supplier-brands",
    path: "/supplier/brands",
    component: () => import("/usr/src/app/pages/supplier/brands.vue").then(m => m.default || m)
  },
  {
    name: "supplier",
    path: "/supplier",
    component: () => import("/usr/src/app/pages/supplier/index.vue").then(m => m.default || m)
  },
  {
    name: "supplier-products",
    path: "/supplier/products",
    component: () => import("/usr/src/app/pages/supplier/products.vue").then(m => m.default || m)
  },
  {
    name: "tests-slugAndId",
    path: "/tests/:slugAndId()",
    component: () => import("/usr/src/app/pages/tests/[slugAndId].vue").then(m => m.default || m)
  },
  {
    name: "tests",
    path: "/tests",
    component: () => import("/usr/src/app/pages/tests/index.vue").then(m => m.default || m)
  }
]