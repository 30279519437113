import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Scripts extends HttpFactory {
  private resource = 'scripts'

  public updateBrandPositions (payload: string) {
    return this.$fetch(
      `${this.resource}/updateBrandPositions`,
      {
        method: 'POST',
        body: JSON.stringify({ payload }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public updateProductPositions (payload: string) {
    return this.$fetch(
      `${this.resource}/updateProductPositions`,
      {
        method: 'POST',
        body: JSON.stringify({ payload }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public updateThisWeekDiscounts (payload: string) {
    return this.$fetch(
      `${this.resource}/updateThisWeekDiscounts`,
      {
        method: 'POST',
        body: JSON.stringify({ payload }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}
