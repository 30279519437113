import HttpFactory from '~/api/http-factory'

export default class Guests extends HttpFactory {
  private resource = 'v1/guests'

  public createGuest () {
    return this.$fetch(this.resource, { method: 'POST' })
  }

  public getGuest (params?: object) {
    const { guestId } = this.authStore

    return this.$fetch(`${this.resource}/${guestId}`, { params })
  }
}
