import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Reviews extends HttpFactory {
  private resource = 'v1/reviews'

  public createReview (data: object) {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getReview (reviewId: string, params: object = {}) {
    return this.$fetch(
      `${this.resource}/${reviewId}`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getReviews (params: object) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public updateReview (reviewId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${reviewId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public deleteReview (reviewId: string, params: object = {}) {
    return this.$fetch(
      `${this.resource}/${reviewId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token), params }
    )
  }
}
